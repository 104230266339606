<template>
  <mu-container class="section-list">
    <div v-for="(c, index) in categories" :key="index" class="category-item">
      <h2 style="margin-bottom: 5px">{{ c.name }}</h2>
      <mu-row gutter class="row">
        <mu-col span="12" sm="8" md="6" lg="3" v-for="s in c.section" :key="s.fid">
          <router-link :to="{ name: 'PageThreads', params: { fid: s.fid } }">
            <mu-card class="section-item">
              <mu-card-media :title="s.name">
                <img v-if="s.cover === true" v-lazy="`/static/img/${s.fid}.jpg`">
                <img v-else-if="typeof s.cover === 'string'" v-lazy="s.cover">
                <img v-else v-lazy="'/static/img/default_cover.jpg'">
              </mu-card-media>
            </mu-card>
          </router-link>
        </mu-col>
      </mu-row>
    </div>
  </mu-container>
</template>

<script>
export default {
  name: 'SectionsList',
  props: {
    categories: Array
  }
}
</script>

<style lang="stylus" scoped>
.section-list
  margin-top 40px
.section-item
  min-height 100px
  margin-top 20px
  margin-left 5px
  margin-right 5px
.mu-card-media-title
  height 100%
  display flex
  justify-content center
  align-items center
.col-3
  max-width 20%
.category-item
  margin-top 40px
  margin-bottom 40px
</style>
