<template>
  <mu-container v-loading="loading" data-mu-loading-overlay-color="rgba(0, 0, 0, .6)">
    <mu-row gutter>
      <mu-col span="12">
        <template v-if="categories">
          <SectionsList :categories="categories"/>
        </template>
        <template v-else>
          <img src="/static/img/blank.jpg" style="display: none">
        </template>
      </mu-col>
    </mu-row>
  </mu-container>
</template>

<script>
import SectionsList from '@/components/SectionsList.vue'

export default {
  name: 'PageSections',
  components: {
    SectionsList
  },
  data () {
    return {
      categories: null,
      loading: false
    }
  },
  mounted () {
    this.loading = true
    this.fetchData()
  },
  methods: {
    fetchData: function () {
      if (this.categories) {
        this.loading = false
      }
      const url = `${process.env.VUE_APP_API}/categories/`
      this.$axios
        .get(url)
        .then(response => {
          this.categories = response.data.categories
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          if (error.response.status === 401) {
            this.$toast.error('未登录')
            this.$router.push({ path: '/login' })
          } else {
            this.$toast.error('获取数据失败')
          }
        })
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
